.plans-container{
    margin-top: 1rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.plan-blur-1{
    width: 32rem;
    height: 26rem;
    top: 2rem;
    left: 0;
}
.plan-blur-2{
    width: 32rem;
    height: 26rem;
    top:2rem;
    right: 0;
}
.plan-header{
    display: flex;
    gap: 1rem;
    padding-left: 5rem;
}
.plan-header>span{
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: bold;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    background: var(--caloryCard);
    color: #fff;
    padding: 1rem;
    border-radius: .5rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan:nth-child(2)>svg{
    fill: #fff;
}
.plan:nth-child(2)>button{
    color: var(--orange);
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-weight: bold;
    font-size: 2rem;
}
plan>:nth-child(5){
    font-size: .8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: .8rem;
}
.feature>img{
   width: 1rem; 
}
@media (max-width: 768px){
    .plan-header{
        flex-direction: column;
        padding-left: 3rem;
    }
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: scale(1);
    }
}