.testimonial{
    display: flex;
    gap: 1rem;
    padding: 2rem;
}
.left-t{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    font-size: 1.5rem;
    color: #fff;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-style: italic;
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
}
.right-t{
    flex: 1 1;
    position: relative;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 15rem;
    right: 5rem;
    top: 2rem;
    object-fit: cover;
    border-radius: 2rem;
}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 15rem;
    right: 6rem;
    border: .1rem solid var(--orange);
    top: 1rem;
    border-radius: 2rem;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 15rem;
    right: 3rem;
    background: var(--orange);
    top: 3rem;
    border-radius: 2rem;
}
.arrows{
    display: flex;
    bottom: .5rem;
    position: absolute;
    left: 6rem;
    gap: 1rem;
}
.arrows>img{
    width: 1rem;
    cursor: pointer;
}
@media (max-width: 768px){
    .testimonial{
        flex-direction: column;
    }
    .left-t>:nth-child(2),
    .left-t>:nth-child(3) {
        font-size: x-large;
    }
    .right-t{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        justify-content: center;
    }
    .right-t>div:nth-of-type(1){
        position: relative;
        left: .1rem;
        top: 0;
        width: 12rem;
        height: 10rem;
    }
    .right-t>div:nth-child(2){
        position: absolute;
        left: 3rem;
        top: .5rem;
        width: 12rem;
        height: 10rem;
    }
    .right-t>img{
        position: absolute;
        align-self: center;
        left: 4rem;
        top: 1rem;
        width: 12rem;
        height: 10rem;
    }
    .arrows{
        gap: .3rem;
        position: relative;
        top: 1rem;
        left: 0;
    }
}