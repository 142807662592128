.program{
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 0 1rem;
}
.program-header {
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
}
.program-categories{
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
    flex-direction: column;
    background: gray;
    color: #fff;
    gap: 1rem;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 2rem;
}
.category:hover{
    background: var(--planCard);
    cursor: pointer;
}
.category>:nth-child(1){
    width: 2rem;
    height: 3rem;
    fill: #fff;
}
.category>:nth-child(2){
    font-size: 1.2rem;
    font-weight: bold;
}
.category>:nth-child(2){
    font-size: .9rem;
    line-height: 25px;
}
.join-now{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.join-now>img{
    width: 1rem;
}
@media (max-width: 768px) {
    .program-header{
        flex-direction: column;
        gap: 1rem;
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .program-categories{
        flex-direction: column;
    }
}
