.footer-container{
    position: relative;
}
.footer-blur-1{
    width: 26rem;
    height: 20rem;
    filter: blur(200px);
    background: red;
    bottom: 0;
    right: 10%;
}
.footer-blur-2{
    width: 26rem;
    height: 20rem;
    filter: blur(200px);
    background: yellow;
    bottom: 0;
    left: 10%;
}
.footer{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-link{
    display: flex;
    gap: 4rem;
}
.social-link>img{
    width: 2rem;
    height: 2rem;
}
.footer-l>img{
    width: 10rem;
}