.join{
    display: flex;
    padding: 0;
    gap: 10rem;
}
.left-j{
    color: #fff;
    font-weight: bold;
    font-size: 3rem;
    text-transform: uppercase;
    position: relative;
    padding-left: 2rem;
}
.left-j>hr{
    position: absolute;
    width: 10rem;
    border:.2rem solid var(--orange);
    margin: -2rem 0;
    border-radius: 20%;
}
.left-j>div{
    display: flex;
    gap: 1rem;
}
.right-j{
   display: flex;
   align-items: center;
   justify-content: flex-end;
}
.form-container{
    display: flex;
    gap: 2rem;
    background: gray;
    padding: 1rem 2rem;
    border-radius: 1rem;
}
.form-container>input{
    background: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    background: var(--orange);
    color: #fff;
}
@media (max-width: 768px){
    .join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
    }
    .right-j{
        width: 100%;
        align-self: center;
    }
    .form-container{
        width: 80%;
        align-items: center;
        justify-content: center;
        padding: .1rem 1.5rem;
    }
    .form-container>button{
        padding: 0;
        font-size: small;
        font-weight: 200;
        height: 80%;
        display: inline-block;
    }
}