.header {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
}
.logo {
   width: 10rem;
   height: 3rem; 
}
.header-menu{
    list-style: none;
    display: flex;
    color: #fff;
    gap: 2rem;
}
.header-menu>li:hover{
    color: var(--orange);
    cursor: pointer;
}
@media (max-width:768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 1rem;
        top: 0;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background: var(--appColor);
        padding: 1rem;
    }
}